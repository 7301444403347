@import './src/scss/variables';
@import './src/scss/game';

$sizes: 7, 11, 15, 17, 19, 21, 23, 25;

@each $size in $sizes {
  .grid-#{$size} {
    grid-template-columns: repeat($size, 40px);
  }
}

.grid {
  display: grid;
  gap: 1px;
  //background-color: grey;
  //border: 2px solid grey;
  padding: 0;
  position: relative;

  .grid__cell {
    background-color: #fff;
    //box-shadow: 0 0 0 1px gray;
    outline: 1px solid $color5;
    aspect-ratio: 1 / 1;
    position: relative;

    .computer {
      background-image: url("../assets/computer-svgrepo-com.svg");
      position: absolute;
      background-color: white;
      background-repeat: no-repeat;
      background-size: 15px;
      height: 15px;
      width: 15px;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .server {
      background-image: url("../assets/server-svgrepo-com.svg");
      position: absolute;
      background-color: lightskyblue;
      background-repeat: no-repeat;
      background-size: 15px;
      height: 15px;
      width: 15px;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .path {
      position: relative;
      width: 100%;
      height: 100%;

      &.powered {
        &::after, &::before {
          background-color: lightskyblue !important;
        }
      }

      &.bead {
        &::after {
          position: absolute;
          top: 40%;
          right: 0;
          bottom: 40%;
          left: 0;
          background-color: #ccc;
          content: " ";
        }

        &::before {
          position: absolute;
          top: 0;
          right: 40%;
          bottom: 40%;
          left: 40%;
          background-color: #ccc;
          content: " ";
        }
      }

      &.line {
        &::after {
          position: absolute;
          top: 40%;
          right: 0;
          bottom: 40%;
          left: 0;
          background-color: #ccc;
          content: " ";
        }
      }

      &.corner {
        &::after {
          position: absolute;
          top: 40%;
          right: 40%;
          bottom: 40%;
          left: 0;
          background-color: #ccc;
          content: " ";
        }

        &::before {
          position: absolute;
          top: 0;
          right: 40%;
          bottom: 40%;
          left: 40%;
          background-color: #ccc;
          content: " ";
        }
      }

      &.cross {
        &::after {
          position: absolute;
          top: 40%;
          right: 0%;
          bottom: 40%;
          left: 0;
          background-color: #ccc;
          content: " ";
        }

        &::before {
          position: absolute;
          top: 0;
          right: 40%;
          bottom: 0%;
          left: 40%;
          background-color: #ccc;
          content: " ";
        }
      }

      &.halfline {
        &::after {
          position: absolute;
          top: 40%;
          right: 40%;
          bottom: 40%;
          left: 0;
          background-color: #ccc;
          content: " ";
        }
      }
    }
  }
}